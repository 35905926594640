var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.items.is_open_news == 1 &&
    _vm.news.length > 0 &&
    _vm.params.limit > 0 &&
    _vm.newsInfo.indexOpenType == 1
  )?_c('section',{staticClass:"iv2-section-news theme__sub-bg",style:(_vm.addValiables())},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"iv2-section-news-title theme__news-heading col-lg-2 d-lg-block d-none text-left"},[_c('h3',{staticClass:"iv2-section-news-title__title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.newsInfo.subtitle)+" ")]),_c('p',{staticClass:"iv2-section-news-title__subtitle m-0"},[_vm._v(" "+_vm._s(_vm.newsInfo.title)+" ")])]),_c('div',{staticClass:"iv2-section-news-title d-lg-none col-12 theme__heading text-center"},[_c('h3',{staticClass:"iv2-section-news-title__title"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.newsInfo.subtitle)+" ")])]),_c('p',{staticClass:"iv2-section-news-title__subtitle m-0"},[_vm._v(" "+_vm._s(_vm.newsInfo.title)+" ")])]),_c('div',{staticClass:"iv2-section-news__edit col-lg-10"},_vm._l((_vm.limitCount),function(item){return _c('NewsLine',{key:item.id,attrs:{"content":item,"current":'top'}})}),1)]),_c('div',{staticClass:"row clearfix"},[_vm._m(0),_c('div',{staticClass:"column full"},[_c('div',{staticClass:"text-center"},[_c('span',[_c('router-link',{staticClass:"iv2-top-news-btn theme__main-btn is-btn is-btn-ghost2 size-14",attrs:{"to":{
                name: 'news',
                query: Object.assign({}, _vm.$route.query),
              }}},[_vm._v(_vm._s(_vm.$store.state.publicLang.page.news_button))])],1)])])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column full"},[_c('div',{staticClass:"spacer height-20"})])}]

export { render, staticRenderFns }