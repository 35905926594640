<template>
  <div :class="current" class="iv2-news-item__line col-12">
    <div class="iv2-news-item__link">
      <router-link
        class="iv2-news-item__link-item"
        :to="{
          name: 'newsDetail',
          params: { id: content.id },
          query: Object.assign({}, $route.query),
        }"
      >
        <div class="row clearfix">
          <div class="iv2-news-item__date col-md-3 col-6 text-xl-center">
            {{ content.opened_at }}
          </div>
          <div class="col-lg-2 col-md-9 col-6">
            <small class="iv2-news-item__category">{{
              content.category_name
            }}</small>
          </div>
          <div class="iv2-news-item__title col-lg-7">
            {{ content.title }}
            <span
              v-if="!content.days_excess"
              class="iv2-news-item__new size-14"
            >
              {{ $store.state.themeLang.general.new_label }}
            </span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object,
    current: String,
  },
};
</script>

<style lang="scss">
.iv2-news-item__line {
  padding: 15px 0;

  &.news-list {
    padding: 30px 0;
    border-top: 1px solid #bcbcbc;

    &:last-of-type {
      border-bottom: 1px solid #bcbcbc;
    }
  }
}

.iv2-news-item__link {
  .iv2-news-item__link-item {
    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }
  }

  .iv2-news-item__date {
    font-size: 1rem;
    font-weight: bold;
  }

  .iv2-news-item__category {
    padding: 0 10px;
    font-weight: bold;
    text-align: center;
    border: 1px solid;
    border-radius: 5px;
    display: inline-block;
  }

  .iv2-news-item__new {
    color: #ce0000;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 2.2;
    margin-left: 0.5rem;
  }

  .iv2-news-item__title {
    font-size: 1rem;
    font-weight: bold;
  }
}

/* TABLET or SP */
@media (max-width: 991px) {
  .iv2-news-item__line {
    padding: 20px 0;
    border-top: 1px solid #bcbcbc;

    &:last-of-type {
      border-bottom: 1px solid #bcbcbc;
    }

    &.news-list {
      padding: 20px 0;
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-news-item__line {
    .iv2-news-item__link {
      .iv2-news-item__date {
        font-size: 14px;
      }
      .iv2-news-item__category {
        font-size: 12px;
      }
      .iv2-news-item__title {
        font-size: 16px;
      }
    }
  }
}
</style>
