<template>
  <div>
    <article class="iv2-article" v-if="pageActive">
      <HomeScroll />
      <!-- メインビジュアル -->
      <LayoutHero
        :items="items"
        :organization="organization"
        :bottom="bottom"
      />

      <!-- コンテンツエリア -->
      <LayoutPattern :items="items" />

      <!-- ニュース -->
      <LayoutNews
        :items="items"
        :rss="rss"
        :theme="theme"
        :news="news"
        :news-info="newsInfo"
      />

      <!-- 外部RSS -->
      <LayoutRss
        :items="items"
        :rss="rss"
        :theme="theme"
        :news="news"
        :news-info="newsInfo"
      />

      <!-- フォーム -->
      <LayoutForm
        :items="items"
        :app="app"
        :theme="theme"
        :rss="rss"
        :news="news"
        :news-info="newsInfo"
      />
    </article>
    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import HomeScroll from "./components/HomeScroll.vue";
import LayoutHero from "./components/LayoutHero.vue";
import LayoutPattern from "./components/LayoutPattern.vue";
import LayoutNews from "./components/LayoutNews.vue";
import LayoutRss from "./components/LayoutRss.vue";
import LayoutForm from "./components/LayoutForm.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Meta from "@/mixins/MetaSettable";
import Hash from "@/mixins/AnchorLink";
import Preview from "@/mixins/Preview";

export default {
  components: {
    HomeScroll,
    LayoutHero,
    LayoutPattern,
    LayoutNews,
    LayoutRss,
    LayoutForm,
    BaseLoading,
  },

  mixins: [Meta, Hash, Preview],

  props: {
    organization: Object,
    rss: Object,
    app: Object,
    bottom: Array,
    theme: Object,
  },

  data() {
    return {
      items: {},
      news: [],
      newsInfo: {},
      pageActive: false,
      loading: true,
    };
  },

  mounted() {
    // TOPページ
    const topRepository = this.$repository.get("topPage");
    const topPage = topRepository.index(
      this.$route.query.mode,
      this.$route.query.api_token
    );
    const self = this;
    topPage
      .then((result) => {
        self.items = result.data.response;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        self.loading = false;
        self.pageActive = true;
      });

    // Newsエリア
    const newsRepository = this.$repository.get("news");
    const news = newsRepository.index({
      type: "news",
      limit: "",
    });
    news
      .then((result) => {
        const res = result.data.response;
        self.news = res.data;
        self.newsInfo.title = res.title;
        self.newsInfo.subtitle = res.subtitle;
        self.newsInfo.type = res.layout_type;
        if (self.$route.query.mode == "preview") {
          self.newsInfo.indexOpenType = 1;
        } else {
          self.newsInfo.indexOpenType = res.open_type;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style lang="scss"></style>
