<template>
  <section
    v-if="items.is_use_form_type != 0"
    id="contactform"
    class="iv2-top-form container-fluid"
    :class="settingPosition()"
    :style="addValiables()"
  >
    <div class="iv2-top-form__heading row clearfix">
      <div class="column full text-center theme__heading">
        <h3>
          <span class="theme__heading-text font-weight-bold">
            {{ contact.subtitle }}
          </span>
        </h3>
        <p class="theme__heading-subtext m-0">
          {{ contact.title }}
        </p>
      </div>
    </div>
    <div v-if="items.is_use_form_type == 1" class="theme__sub-bg">
      <div class="spacer height-40 theme__sub-bg"></div>
      <div v-if="contact.form_type == 'gen'">
        <BaseFormBasic :contact="contact" />
      </div>
      <div v-else-if="contact.form_type == 'biz'">
        <BaseFormBusiness :contact="contact" />
      </div>
      <div v-else-if="contact.form_type == 'cus'">
        <BaseFormCustomer :contact="contact" />
      </div>
    </div>
    <div v-else-if="items.is_use_form_type == 2" class="theme__main-bg">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="spacer height-40 theme__main-bg"></div>
            <iframe
              :src="formUrl"
              :height="items.form_view_height"
              width="100%"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BaseFormBasic from "@/components/items/BaseFormBasic.vue";
import BaseFormBusiness from "@/components/items/BaseFormBusiness.vue";
import BaseFormCustomer from "@/components/items/BaseFormCustomer.vue";

export default {
  components: {
    BaseFormBasic,
    BaseFormBusiness,
    BaseFormCustomer,
  },

  props: {
    items: Object,
    app: Object,
    theme: Object,
    rss: Object,
    news: Array,
    newsInfo: Object,
  },

  data() {
    return {
      contact: {},
      openType: "",
      loading: true,
      formUrl: process.env.VUE_APP_FORM_URL,
    };
  },

  mounted() {
    if (this.items.is_use_form_type == 2) {
      this.formUrl +=
        this.app.id + "/" + this.items.form_id + "/form?disableHeadline=1";
    }
    const contactsRepository = this.$repository.get("contacts");
    const contacts = contactsRepository.show();
    const self = this;
    contacts
      .then((result) => {
        const res = result.data;
        self.contact = res;

        // TOPで表示するモードに設定
        self.contact.viewTop = true;

        // フォームの見出しを利用するかどうかの制御
        self.contact.viewHeadline = false;

        // 文字色制御
        self.contact.generalColor = "#000";

        if (self.$route.query.mode == "preview") {
          self.openType = 1;
        } else {
          self.openType = res.open_type;
        }
      })
      .catch((error) => {
        console.error(error);
        self.openType = 0;
      })
      .finally(() => {
        // ...
      });
  },

  methods: {
    addValiables() {
      return {
        "--bg-color": this.theme.basic_bg_color,
      };
    },

    settingPosition() {
      return this.rss.is_linked_blog == 1 && this.rss.articles.length > 0
        ? "pos-left"
        : "pos-right";
    },
  },
};
</script>

<style lang="scss">
.iv2-top-form {
  margin: 0;
  padding: 240px 0 0;
  position: relative;

  &.pos-left::before,
  &.pos-right::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    background: var(--main-bg);
  }

  &.pos-left::before {
    left: 0;
  }

  &.pos-right::before {
    right: 0;
  }

  .iv2-top-form__heading {
    position: absolute;
    top: 120px;
    z-index: 1;
    width: 100%;

    .theme__heading-subtext {
      color: #fff;
    }
  }
}

// レスポンシブ対応

@media (min-width: 1401px) {
  .iv2-top-form {
    &.pos-left::before,
    &.pos-right::before {
      width: 8vw;
    }
  }
}

@media (max-width: 1400px) {
  .iv2-top-form {
    &.pos-left::before,
    &.pos-right::before {
      width: 3vw;
    }
  }
}
</style>
