<template>
  <section class="iv2-page-header">
    <div
      v-if="items.header_type == 'add_catch'"
      class="iv2-page-header__type-add-catch fit-img overflow-hidden"
    >
      <picture>
        <source
          type="image/webp"
          :srcset="
            items.header_image + $store.state.publicLang.general.image_webp
          "
        />
        <img
          class="iv2-page-header__img"
          :src="items.header_image"
          :alt="items.title + $store.state.publicLang.page.alt"
        />
      </picture>
      <div class="iv2-page-header__cover position-absolute"></div>
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title">{{ items.title }}</h1>
        <p
          v-if="items.subtitle"
          class="iv2-page-header__sub-title m-0 mt-1 iv2-page-header__sub-title_icon"
        >
          {{ items.subtitle }}
        </p>
      </div>
      <div class="iv2-page-header__text position-relative text-left">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-xl-5">
              <p
                v-if="items.header_title"
                v-html="items.header_title"
                class="iv2-page-header__main-lead"
              ></p>
              <p
                v-if="items.header_subtitle"
                v-html="items.header_subtitle"
                class="iv2-page-header__sub-lead"
              ></p>
            </div>
            <div class="col-12 col-xl-7">
              <p
                v-if="items.header_summary_text"
                v-html="items.header_summary_text"
                class="iv2-page-header__description text-pre"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="items.header_type == 'add_bg'"
      class="iv2-page-header__type-add-bg fit-img overflow-hidden"
    >
      <picture>
        <source
          type="image/webp"
          :srcset="
            items.header_image + $store.state.publicLang.general.image_webp
          "
        />
        <img
          class="iv2-page-header__img"
          :src="items.header_image"
          :alt="items.title + $store.state.publicLang.page.alt"
        />
      </picture>
      <div class="iv2-page-header__cover position-absolute"></div>
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title h4 m-0">{{ items.title }}</h1>
        <p v-if="items.subtitle" class="iv2-page-header__sub-title m-0 mt-1">
          {{ items.subtitle }}
        </p>
      </div>
    </div>
    <div
      v-else-if="items.header_type == 'add_cover'"
      class="iv2-page-header__type-add-cover overflow-hidden"
    >
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title theme__page-header-title h4 m-0">
          {{ items.title }}
        </h1>
        <p v-if="items.subtitle" class="iv2-page-header__sub-title m-0 mt-1">
          {{ items.subtitle }}
        </p>
      </div>
      <div class="iv2-page-header__img-band fit-img">
        <picture>
          <source
            type="image/webp"
            :srcset="
              items.header_image + $store.state.publicLang.general.image_webp
            "
          />
          <img
            class="iv2-page-header__img"
            :src="items.header_image"
            :alt="items.title + $store.state.publicLang.page.alt"
          />
        </picture>
        <div class="iv2-page-header__cover position-absolute"></div>
      </div>
    </div>
    <div
      v-else-if="items.header_type == 'text_only'"
      class="iv2-page-header__type-text-only overflow-hidden"
    >
      <div class="iv2-page-header__title position-relative">
        <h1 class="iv2-page-header__main-title theme__page-header-title h4 m-0">
          {{ items.title }}
        </h1>
        <p
          v-if="items.subtitle"
          class="iv2-page-header__sub-title theme__page-header-sub-title m-0 mt-1"
        >
          {{ items.subtitle }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: Object,
  },
};
</script>

<style lang="scss">
.iv2-page-header {
  text-align: center;

  .iv2-page-header__type-add-cover {
    .iv2-page-header__title {
      .iv2-page-header__main-title {
        font-size: 1.375rem;
        padding: 30px 0px 25px;
        font-weight: bold;
        display: inline-block;
      }
    }
  }

  .iv2-page-header__type-text-only {
    .iv2-page-header__title {
      .iv2-page-header__main-title {
        font-size: 1.375rem;
        padding: 30px 0px 10px;
        font-weight: bold;
        display: inline-block;
      }
    }
  }

  .iv2-page-header__title {
    .iv2-page-header__sub-title {
      font-weight: 400;
      position: relative;
      z-index: 1;

      &_icon {
        &:before {
          content: "";
          width: 1px;
          height: 60px;
          background: #fff;
          position: absolute;
          top: 60px;
          left: 50%;
          transform: translateX(-50%);
        }

        &::after {
          content: "";
          width: 10px;
          height: 10px;
          background: #fff;
          border-radius: 100px;
          position: absolute;
          top: 115px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .iv2-page-header__img-band {
    .iv2-page-header__cover {
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  .iv2-page-header__type-add-catch,
  .iv2-page-header__type-add-bg {
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);

    .iv2-page-header__img {
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .iv2-page-header__cover {
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.15);
    }

    .iv2-page-header__title,
    .iv2-page-header__text {
      z-index: 3;
      color: #fff;

      .iv2-page-header__main-lead {
        line-height: 1.2;
      }

      .iv2-page-header__sub-lead {
        line-height: 1.5;
      }
    }
  }

  .iv2-page-header__type-add-catch {
    padding-bottom: 55px;
  }
  .iv2-page-header__type-add-bg {
    padding-bottom: 100px;
  }
  .iv2-page-header__type-add-cover {
    .iv2-page-header__title {
      .iv2-page-header__sub-title {
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
      }
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-page-header {
    margin-top: 100px;
  }

  .iv2-page-header__type-add-catch,
  .iv2-page-header__type-add-bg {
    .iv2-page-header__title {
      padding: 30px 50px 0px;
    }
    .iv2-page-header__text {
      margin-top: 100px;

      .iv2-page-header__main-lead {
        font-size: 1.875rem;
        text-align: left;
      }

      .iv2-page-header__sub-lead {
        font-size: 1rem;
        text-align: left;
      }

      .iv2-page-header__description {
        line-height: 1.7;
        font-size: 1rem;
        letter-spacing: 0.01rem;
      }
    }
  }

  .iv2-page-header__type-add-cover,
  .iv2-page-header__type-text-only {
    .iv2-page-header__title {
      padding: 32px 50px;
      z-index: 11;
    }
  }

  .iv2-page-header__img-band {
    height: 150px;
    margin-top: -75px;
    position: relative;
    z-index: 1;
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-page-header {
    margin-top: 70px;
  }

  .iv2-page-header__title {
    padding: 60px 30px;
  }

  .iv2-page-header__type-add-catch,
  .iv2-page-header__type-add-bg {
    .iv2-page-header__title {
      padding: 30px;
    }

    .iv2-page-header__text {
      margin-top: 90px;

      .iv2-page-header__description {
        line-height: 1.5;
      }

      .iv2-page-header__main-lead {
        font-size: 2rem;
        text-align: center;
      }

      .iv2-page-header__sub-lead {
        text-align: center;
      }
    }
  }

  .iv2-page-header__img-band {
    height: 150px;
    margin-top: -105px;
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-page-header {
    margin-top: 60px;

    .iv2-page-header__title {
      padding: 20px;

      .iv2-page-header__main-title {
        font-size: 1.3rem;
      }
    }

    .iv2-page-header__type-add-catch,
    .iv2-page-header__type-add-bg {
      padding-bottom: 30px;

      .iv2-page-header__text {
        padding: 0 20px;
        margin-top: 70px;

        .iv2-page-header__main-lead {
          font-size: 1.3rem;
          text-align: center;
        }

        .iv2-page-header__sub-lead {
          text-align: center;
        }

        .iv2-page-header__description {
          font-size: 0.9rem;
        }
      }
    }

    .iv2-page-header__type-add-cover {
      .iv2-page-header__title {
        padding: 50px 20px;
      }
    }
  }

  .iv2-page-header__img-band {
    height: 100px;
    margin-top: -95px;
  }
}
</style>
