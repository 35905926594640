<template>
  <article
    v-if="banner != null"
    class="iv2-floating-banner"
    :class="banner.position_type + ' ' + viewMode + ' ' + hasBottomNav"
  >
    <a
      rel="noopener"
      class="iv2-floating-banner__item"
      :href="banner.link_href"
      :target="setTargetAttr(banner.is_external)"
      @click="clickEventTrigger(banner.analytics_event_name)"
    >
      <!-- PC用 -->
      <picture :class="banner.image_sp != null ? 'd-sm-block d-none' : ''">
        <source
          type="image/webp"
          :srcset="banner.image + $store.state.publicLang.general.image_webp"
        />
        <img class="m-0" :src="banner.image" :alt="banner.alternative_text" />
      </picture>
      <!-- PC用 -->
      <!-- SP用 -->
      <picture v-if="banner.image_sp != null" class="d-sm-none d-block">
        <source
          type="image/webp"
          :srcset="banner.image_sp + $store.state.publicLang.general.image_webp"
        />
        <img
          class="m-0"
          :src="banner.image_sp"
          :alt="banner.alternative_text"
        />
      </picture>
      <!-- SP用 -->
    </a>
  </article>
</template>

<script>
export default {
  props: {
    banner: Object,
    bottom: Array,
  },

  data() {
    return {
      viewMode: "",
      hasBottomNav: "",
    };
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);

    // ボトムメニューの有無で余白を付けるかどうかを制御する
    this.hasBottomNav += this.bottom.length > 0 ? " has-bottom-nav" : "";
  },

  methods: {
    handleScroll() {
      let docHeight = document.body.clientHeight - 1200;
      this.viewMode =
        window.scrollY > 250 && window.scrollY <= docHeight ? "fadein" : "";
    },

    setTargetAttr(flag) {
      return flag === 1 ? "_blank" : "_self";
    },

    clickEventTrigger(event) {
      return this.$gtag != undefined &&
        event != undefined &&
        event != "" &&
        event != null
        ? this.$gtag.event("click", {
            event_category: "Banner",
            event_label: event,
            value: 1,
          })
        : false;
    },
  },
};
</script>

<style lang="scss">
.iv2-floating-banner {
  position: fixed;
  bottom: 20px;
  transition: all 500ms;
  opacity: 0;
  visibility: hidden;
  transform: translate(0px, 50px);
  z-index: 10;
  &.bottom_right {
    right: 100px;
  }
  &.bottom_left {
    left: 20px;
  }
  &.fadein {
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px);
  }

  .iv2-floating-banner__item {
    img {
      max-width: 300px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-floating-banner {
    &.bottom_right {
      right: 50px;
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-floating-banner {
    &.has-bottom-nav {
      bottom: 60px;
    }
    &.bottom_right {
      right: 0;
    }
    &.bottom_left {
      left: 0;
    }

    .iv2-floating-banner__item {
      img {
        max-width: 100%;
        max-height: 200px;
      }
    }
  }
}

@media (max-width: 575px) {
  .iv2-floating-banner {
    .iv2-floating-banner__item {
      img {
        max-height: 150px;
      }
    }
  }
}
</style>
