<template>
  <transition name="scroll" appear>
    <div
      v-if="scrollY < 300"
      class="iv2-home__scroll-bar position-fixed d-none d-xl-block text-center"
      @click="scroll"
    >
      <span class="iv2-home__scroll-bar-text">{{
        $store.state.themeLang.general.scroll
      }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      scrollY: 0,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },

    scroll() {
      var section = document.getElementsByClassName("iv2-header");
      var header = section[0].clientHeight - 60;
      var height = window.innerHeight - header;
      window.scrollTo({
        top: height,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.iv2-home__scroll-bar {
  right: 22px;
  bottom: 65px;
  width: 20px;
  height: 110px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 5px;
  cursor: pointer;
  z-index: 1111;

  &::before {
    content: "";
    width: 1px;
    height: 40px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  &::after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    border-radius: 100px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
  }

  .iv2-home__scroll-bar-text {
    transform: rotate(90deg);
    display: block;
    word-break: initial;
  }

  &.scroll-enter-active,
  &.scroll-leave-active {
    transition: all 1s ease;
  }

  &.scroll-enter,
  &.scroll-leave-to {
    transform: translateY(-150px) !important;
    opacity: 0;
  }
}
</style>
