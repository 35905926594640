<template>
  <div>
    <article class="iv2-article">
      <Error404 v-if="openType === 0" />

      <div v-else-if="openType === 1">
        <BaseTitle :items="items" />
        <div v-if="contact.form_type == 'gen'">
          <BaseFormBasic :contact="contact" />
        </div>
        <div v-else-if="contact.form_type == 'biz'">
          <BaseFormBusiness :contact="contact" />
        </div>
        <div v-else-if="contact.form_type == 'cus'">
          <BaseFormCustomer :contact="contact" />
        </div>
      </div>
    </article>
    <div v-if="loading">
      <BaseLoading />
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/items/BaseTitle.vue";
import BaseFormBasic from "@/components/items/BaseFormBasic.vue";
import BaseFormBusiness from "@/components/items/BaseFormBusiness.vue";
import BaseFormCustomer from "@/components/items/BaseFormCustomer.vue";
import BaseLoading from "@/components/items/BaseLoading.vue";
import Error404 from "@/components/systems/Page404.vue";
import Meta from "@/mixins/MetaSettable";
import Preview from "@/mixins/Preview";

export default {
  components: {
    BaseTitle,
    BaseFormBasic,
    BaseFormBusiness,
    BaseFormCustomer,
    BaseLoading,
    Error404,
  },

  mixins: [Meta, Preview],

  data() {
    return {
      contact: {},
      items: {
        title: "",
        subtitle: "",
        header_type: "text_only",
      },
      openType: "",
      loading: true,
    };
  },

  mounted() {
    const contactsRepository = this.$repository.get("contacts");
    const contacts = contactsRepository.show();
    const self = this;
    contacts
      .then((result) => {
        const res = result.data;
        self.contact = res;
        self.items.title = res.title;
        self.items.subtitle = res.subtitle;
        if (self.$route.query.mode == "preview") {
          self.openType = 1;
        } else {
          self.openType = res.open_type;
        }

        // フォームの見出しを利用するかどうかの制御
        self.contact.viewHeadline = true;
      })
      .catch((error) => {
        console.error(error);
        self.openType = 0;
      })
      .finally(() => {
        self.loading = false;
      });
  },
};
</script>

<style lang="scss"></style>
