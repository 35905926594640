<template>
  <section>
    <div class="iv2-hero">
      <div
        v-if="items.main_visual_type == 'slider'"
        :class="displayType"
        class="iv2-hero-slider position-relative overflow-hidden"
      >
        <div
          v-for="media in items.main_visuals"
          :key="media.id"
          class="iv2-hero-content"
        >
          <transition name="slider-fade" appear>
            <div v-if="currentSlide == media.sort_key" class="iv2-hero-inner">
              <!-- PC画像 -->
              <picture :class="setDisplayTypeByDeviceSize(media, 'pc')">
                <source
                  type="image/webp"
                  :srcset="
                    media.file_url + $store.state.publicLang.general.image_webp
                  "
                />
                <img
                  class="iv2-hero-image"
                  :src="media.file_url"
                  :alt="
                    organization.name + $store.state.publicLang.general.hero_alt
                  "
                />
              </picture>
              <!-- PC画像 -->
              <!-- SP画像 -->
              <picture
                v-if="media.file_url_sp != null"
                :class="setDisplayTypeByDeviceSize(media, 'sp')"
              >
                <source
                  type="image/webp"
                  :srcset="
                    media.file_url_sp +
                    $store.state.publicLang.general.image_webp
                  "
                />
                <img
                  class="iv2-hero-image"
                  :src="media.file_url_sp"
                  :alt="
                    organization.name + $store.state.publicLang.general.hero_alt
                  "
                />
              </picture>
              <!-- SP画像 -->
              <div class="iv2-hero-cover position-absolute">
                <a
                  v-if="media.use_link === 1 && media.is_external === 0"
                  :href="media.link_href"
                  target="_self"
                  class="iv2-hero-link d-block w-100 h-100"
                ></a>
                <a
                  v-else-if="media.use_link === 1 && media.is_external === 1"
                  :href="media.link_href"
                  target="_blank"
                  class="iv2-hero-link d-block w-100 h-100"
                ></a>
              </div>
              <div class="iv2-hero-catchphrase position-absolute text-center">
                <a
                  v-if="media.use_link === 1 && media.is_external === 0"
                  :href="media.link_href"
                  target="_self"
                  class="iv2-hero-link position-absolute d-block w-100 h-100"
                ></a>
                <a
                  v-else-if="media.use_link === 1 && media.is_external === 1"
                  :href="media.link_href"
                  target="_blank"
                  class="iv2-hero-link position-absolute d-block w-100 h-100"
                ></a>
                <div class="container-fluid">
                  <div class="row">
                    <picture
                      v-if="
                        media.catchphrase_type == 'img' &&
                        media.catchphrase_image_url
                      "
                      class="iv2-hero-catchphrase__picture text-center m-auto"
                    >
                      <source
                        type="image/webp"
                        :srcset="
                          media.catchphrase_image_url +
                          $store.state.publicLang.general.image_webp
                        "
                      />
                      <img
                        :src="media.catchphrase_image_url"
                        :alt="
                          organization.name +
                          $store.state.publicLang.general.catchphrase_alt
                        "
                        class="iv2-hero-catchphrase__img"
                      />
                    </picture>
                    <div
                      :class="
                        media.catchphrase_type != 'text'
                          ? 'text-center'
                          : 'text-left'
                      "
                      class="iv2-hero-catchphrase__text col-md-12 mx-auto"
                    >
                      <h2
                        v-if="
                          media.catchphrase_type == 'text' &&
                          media.catchphrase_text
                        "
                        v-text="media.catchphrase_text"
                        class="iv2-hero__main-title text-pre"
                      ></h2>
                      <h3
                        v-if="media.summary_text"
                        v-text="media.summary_text"
                        class="iv2-hero__sub-title text-pre"
                      ></h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <!-- スライダーのスライド数を示すインジゲーター -->
        <div
          v-if="
            items.main_visual_type == 'slider' && items.main_visuals.length > 1
          "
          class="iv2-hero-indicate position-absolute"
        >
          <div class="position-relative">
            <span
              class="iv-hero-indicate__number position-absolute d-sm-inline d-none"
              v-text="currentSlide + '/' + items.main_visuals.length"
            ></span>
            <span class="iv-hero-indicate__meter position-absolute"></span>
            <span
              class="iv-hero-indicate__meter-progress position-absolute"
              :style="'width: ' + indigateProgress + 'px'"
            ></span>
          </div>
        </div>
      </div>
      <div
        v-else-if="items.main_visual_type == 'movie'"
        :class="displayType"
        class="iv2-hero-video position-relative overflow-hidden"
      >
        <!-- PC用 -->
        <video
          :class="setDisplayTypeByDeviceSize(data, 'pc')"
          class="iv2-hero-content fit-img"
          autoplay
          muted
          loop
          playsinline
        >
          <source :src="data.file_url" type="video/mp4" />
        </video>
        <!-- PC用 -->
        <!-- SP用 -->
        <video
          v-if="data.file_url_sp != null"
          :class="setDisplayTypeByDeviceSize(data, 'sp')"
          class="iv2-hero-content fit-img"
          autoplay
          muted
          loop
          playsinline
        >
          <source :src="data.file_url_sp" type="video/mp4" />
        </video>
        <!-- SP用 -->

        <div class="iv2-hero-cover position-absolute">
          <a
            v-if="data.use_button === 1 && data.is_external === 0"
            :href="data.link_href"
            target="_self"
            class="iv2-hero-link d-block w-100 h-100"
          ></a>
          <a
            v-else-if="data.use_button === 1 && data.is_external === 1"
            :href="data.link_href"
            target="_blank"
            class="iv2-hero-link d-block w-100 h-100"
          ></a>
        </div>
        <div
          class="iv2-hero-catchphrase container-fluid position-absolute text-center"
        >
          <div class="row justify-content-md-start justify-content-center">
            <picture
              v-if="
                data.catchphrase_type == 'img' && data.catchphrase_image_url
              "
              class="iv2-hero-catchphrase__picture"
            >
              <source
                type="image/webp"
                :srcset="
                  data.catchphrase_image_url +
                  $store.state.publicLang.general.image_webp
                "
              />
              <img
                :src="data.catchphrase_image_url"
                :alt="
                  organization.name +
                  $store.state.publicLang.general.catchphrase_alt
                "
                class="iv2-hero-catchphrase__img"
              />
            </picture>
            <div
              :class="
                data.catchphrase_type != 'text' ? 'text-center' : 'text-left'
              "
              class="iv2-hero-catchphrase__text col-md-12 mx-auto"
            >
              <h2
                v-if="data.catchphrase_type == 'text' && data.catchphrase_text"
                v-text="data.catchphrase_text"
                class="iv2-hero__main-title text-pre"
              ></h2>
              <h3
                v-if="data.summary_text"
                v-text="data.summary_text"
                class="iv2-hero__sub-title text-pre"
              ></h3>
            </div>
          </div>
          <div
            v-if="data.use_button == 1"
            :class="
              data.catchphrase_type != 'text'
                ? 'justify-content-md-start justify-content-center'
                : 'text-md-left text-sm-center'
            "
            class="row mt-3"
          >
            <span>
              <a
                :href="data.link_href"
                :target="windowSelected"
                class="iv2-hero-btn is-btn-ghost2 py-2 pr-5 pl-5 position-relative"
                >{{ data.button_label
                }}<i
                  class="fas fa-play position-absolute d-flex"
                  style="
                    top: 0px;
                    bottom: 0px;
                    align-items: center;
                    right: 15px;
                  "
                ></i
              ></a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: Object,
    organization: Object,
    bottom: Array,
  },

  data() {
    return {
      currentSlide: 1,
      indigateProgress: 0,
      displayType: "",
      windowSelected: "",
      data: {},
    };
  },

  created() {
    if (this.items.main_visual_type == "movie") {
      this.data = this.items.main_visuals[0];
      if (this.data.is_external == 1) {
        this.windowSelected = "_blank";
      } else {
        this.windowSelected = "_self";
      }
    }

    this.displayType = this.items.display_type;

    // ボトムメニューの有無で余白を付けるかどうかを制御する
    this.displayType += this.bottom.length > 0 ? " has-bottom-nav" : "";
  },

  mounted() {
    if (
      this.items.main_visual_type == "slider" &&
      this.items.main_visuals.length > 1
    ) {
      this.setIndigateProgress();
      setInterval(() => {
        this.currentSlide =
          this.currentSlide < this.items.main_visuals.length
            ? this.currentSlide + 1
            : 1;

        this.setIndigateProgress();
      }, this.items.slide_second);
    }
  },

  methods: {
    // メインビジュアルのスライドごとの表示クラス制御
    setDisplayTypeByDeviceSize(media = {}, type = "pc") {
      let ret = "";
      if (media != {} && media.file_url_sp != null) {
        switch (type) {
          // SP時
          case "sp":
            ret = "d-sm-none d-inline";
            break;
          // その他(デフォルト:PC)
          default:
            ret = "d-sm-inline d-none";
        }
      }
      return ret;
    },

    // スライドの進捗
    setIndigateProgress() {
      if (this.$route.name == "index") {
        var tmp = 100 / this.items.main_visuals.length;
        this.indigateProgress = document.querySelector(
          ".iv-hero-indicate__meter"
        ).clientWidth;
        this.indigateProgress *= (tmp * this.currentSlide) / 100;
      }
    },
  },
};
</script>

<style lang="scss">
.slider-fade-enter-active,
.slider-fade-leave-active {
  transition: opacity 1s;
}
.slider-fade-enter,
.slider-fade-leave-to {
  opacity: 0;
}

.iv2-hero {
  .full_screen,
  .responsive.iv2-hero-slider {
    height: calc(100vh);
  }

  .full_screen {
    &.iv2-hero-video {
      .iv2-hero-content {
        height: 100%;
      }
    }
  }

  .iv2-hero-slider {
    .iv2-hero-content {
      position: absolute;
      width: 100%;
      height: 100%;

      .iv2-hero-inner {
        width: 100%;
        height: 100%;
        z-index: 11;

        .iv2-hero-image {
          margin: 0 !important;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .iv2-hero-video {
    .iv2-hero-content {
      margin: 0 !important;
      width: 100%;
      object-fit: cover;
      display: block;
    }
    .iv2-hero-cover {
      display: none;
      z-index: 12;
    }
  }

  .iv2-hero-cover {
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .iv2-hero-link {
    cursor: pointer;
    z-index: 21;
  }

  .iv2-hero-catchphrase {
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: auto;
    color: #ffffff;
    z-index: 11;
    animation-name: fade;
    animation-duration: 3s;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);

    .iv2-hero-catchphrase__picture {
      width: 600px;
      .iv2-hero-catchphrase__img {
        margin: 0;
        max-height: 300px;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
      }
    }

    .iv2-hero-catchphrase__text {
      color: #614632; //customize
      text-shadow: 0px 0px 10px rgb(255 255 255); //customize
      .iv2-hero__main-title,
      .iv2-hero__sub-title {
        font-weight: bold;
      }

      .iv2-hero__main-title {
        margin-bottom: 30px;
      }

      .iv2-hero__sub-title {
        font-size: 1rem;
        line-height: 2;
      }
    }
  }

  .iv2-hero-btn {
    max-width: 100%;
    color: #6c4d38;
    font-weight: bold;
    background: none;
    border-radius: 50px;
    border: 2px solid #f0eceb;
    background-color: #f0eceb;
    transition: 0.3s;
    text-shadow: none;
    font-size: 14px; //customize

    &:hover {
      text-decoration: none;
    }
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .iv2-hero-indicate {
    left: 65px;
    bottom: 60px;
    width: 550px;

    .iv-hero-indicate__number {
      left: 0;
      color: #fff;
      font-size: 0.9rem;
    }

    .iv-hero-indicate__meter,
    .iv-hero-indicate__meter-progress {
      right: 0;
      top: 13px;
      height: 2px;
      display: block;
    }

    .iv-hero-indicate__meter {
      width: 500px;
      background: #fff;
    }

    .iv-hero-indicate__meter-progress {
      content: "";
      left: 50px;
      transition: width 500ms ease, left 500ms ease;
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-hero {
    .responsive {
      &.iv2-hero-video {
        height: calc(100vh);

        .iv2-hero-content {
          height: 100%;
        }
      }
    }

    .iv2-hero-catchphrase {
      padding: 40px 0;
      .iv2-hero-catchphrase__text {
        .iv2-hero__main-title {
          font-size: 2.5rem;
          line-height: 1.4;
        }
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-hero {
    .full_screen {
      height: calc(100vh);
    }

    .responsive {
      &.iv2-hero-slider {
        height: 50vw;
      }

      .iv2-hero-catchphrase {
        .iv2-hero-catchphrase__picture {
          .iv2-hero-catchphrase__img {
            max-height: 180px;
          }
        }
      }
    }

    .iv2-hero-catchphrase {
      padding: 20px 0;
      .iv2-hero-catchphrase__text {
        .iv2-hero__main-title {
          font-size: 1.8rem;
        }

        .iv2-hero__sub-title {
          font-size: 0.9rem;
          line-height: 2;
        }
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-hero {
    .full_screen {
      height: calc(100vh);

      .iv2-hero-catchphrase {
        padding: 20px 0; //customize
        .iv2-hero-catchphrase__picture {
          width: 90%;

          .iv2-hero-catchphrase__img {
            max-height: 250px;
          }
        }

        .iv2-hero-catchphrase__text {
          .iv2-hero__main-title,
          .iv2-hero__sub-title {
            text-align: center;
          }

          .iv2-hero__main-title {
            font-size: 1.6rem;
            line-height: 1.6;
          }

          .iv2-hero__sub-title {
            font-size: 0.8rem;
            line-height: 1.8;
          }
        }
      }

      .iv2-hero-btn {
        font-size: 12px; //customize
      }

      &.has-bottom-nav {
        height: calc(100vh - calc(60px + env(safe-area-inset-bottom)));
      }
    }

    .responsive {
      &.iv2-hero-slider {
        height: 50vw;
      }

      &.iv2-hero-video {
        .iv2-hero-cover {
          display: block;
        }
      }

      .iv2-hero-catchphrase {
        display: none; //customize
        padding-top: 1rem;
        .iv2-hero-catchphrase__picture {
          width: 80%;

          .iv2-hero-catchphrase__img {
            max-height: 150px;
          }
        }

        .iv2-hero-catchphrase__text,
        .iv2-hero-btn {
          display: none;
        }
      }
    }

    .iv2-hero-indicate {
      opacity: 0.75;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;

      .iv-hero-indicate__meter,
      .iv-hero-indicate__meter-progress {
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
}
</style>
