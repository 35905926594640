import Repository from "./repository";
import store from "@/store/index.js";

const storage = process.env.VUE_APP_STORAGE_URL;

export default {
  theme() {
    const lang = store.state.language;
    return Repository.get(`${storage}common/lang/theme-lp-a/${lang}.json`);
  },

  public() {
    const lang = store.state.language;
    return Repository.get(`${storage}common/lang/public/${lang}.json`);
  },
};
