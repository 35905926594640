var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"iv2-hero"},[(_vm.items.main_visual_type == 'slider')?_c('div',{staticClass:"iv2-hero-slider position-relative overflow-hidden",class:_vm.displayType},[_vm._l((_vm.items.main_visuals),function(media){return _c('div',{key:media.id,staticClass:"iv2-hero-content"},[_c('transition',{attrs:{"name":"slider-fade","appear":""}},[(_vm.currentSlide == media.sort_key)?_c('div',{staticClass:"iv2-hero-inner"},[_c('picture',{class:_vm.setDisplayTypeByDeviceSize(media, 'pc')},[_c('source',{attrs:{"type":"image/webp","srcset":media.file_url + _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"iv2-hero-image",attrs:{"src":media.file_url,"alt":_vm.organization.name + _vm.$store.state.publicLang.general.hero_alt}})]),(media.file_url_sp != null)?_c('picture',{class:_vm.setDisplayTypeByDeviceSize(media, 'sp')},[_c('source',{attrs:{"type":"image/webp","srcset":media.file_url_sp +
                  _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"iv2-hero-image",attrs:{"src":media.file_url_sp,"alt":_vm.organization.name + _vm.$store.state.publicLang.general.hero_alt}})]):_vm._e(),_c('div',{staticClass:"iv2-hero-cover position-absolute"},[(media.use_link === 1 && media.is_external === 0)?_c('a',{staticClass:"iv2-hero-link d-block w-100 h-100",attrs:{"href":media.link_href,"target":"_self"}}):(media.use_link === 1 && media.is_external === 1)?_c('a',{staticClass:"iv2-hero-link d-block w-100 h-100",attrs:{"href":media.link_href,"target":"_blank"}}):_vm._e()]),_c('div',{staticClass:"iv2-hero-catchphrase position-absolute text-center"},[(media.use_link === 1 && media.is_external === 0)?_c('a',{staticClass:"iv2-hero-link position-absolute d-block w-100 h-100",attrs:{"href":media.link_href,"target":"_self"}}):(media.use_link === 1 && media.is_external === 1)?_c('a',{staticClass:"iv2-hero-link position-absolute d-block w-100 h-100",attrs:{"href":media.link_href,"target":"_blank"}}):_vm._e(),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[(
                      media.catchphrase_type == 'img' &&
                      media.catchphrase_image_url
                    )?_c('picture',{staticClass:"iv2-hero-catchphrase__picture text-center m-auto"},[_c('source',{attrs:{"type":"image/webp","srcset":media.catchphrase_image_url +
                        _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"iv2-hero-catchphrase__img",attrs:{"src":media.catchphrase_image_url,"alt":_vm.organization.name +
                        _vm.$store.state.publicLang.general.catchphrase_alt}})]):_vm._e(),_c('div',{staticClass:"iv2-hero-catchphrase__text col-md-12 mx-auto",class:media.catchphrase_type != 'text'
                        ? 'text-center'
                        : 'text-left'},[(
                        media.catchphrase_type == 'text' &&
                        media.catchphrase_text
                      )?_c('h2',{staticClass:"iv2-hero__main-title text-pre",domProps:{"textContent":_vm._s(media.catchphrase_text)}}):_vm._e(),(media.summary_text)?_c('h3',{staticClass:"iv2-hero__sub-title text-pre",domProps:{"textContent":_vm._s(media.summary_text)}}):_vm._e()])])])])]):_vm._e()])],1)}),(
          _vm.items.main_visual_type == 'slider' && _vm.items.main_visuals.length > 1
        )?_c('div',{staticClass:"iv2-hero-indicate position-absolute"},[_c('div',{staticClass:"position-relative"},[_c('span',{staticClass:"iv-hero-indicate__number position-absolute d-sm-inline d-none",domProps:{"textContent":_vm._s(_vm.currentSlide + '/' + _vm.items.main_visuals.length)}}),_c('span',{staticClass:"iv-hero-indicate__meter position-absolute"}),_c('span',{staticClass:"iv-hero-indicate__meter-progress position-absolute",style:('width: ' + _vm.indigateProgress + 'px')})])]):_vm._e()],2):(_vm.items.main_visual_type == 'movie')?_c('div',{staticClass:"iv2-hero-video position-relative overflow-hidden",class:_vm.displayType},[_c('video',{staticClass:"iv2-hero-content fit-img",class:_vm.setDisplayTypeByDeviceSize(_vm.data, 'pc'),attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.data.file_url,"type":"video/mp4"}})]),(_vm.data.file_url_sp != null)?_c('video',{staticClass:"iv2-hero-content fit-img",class:_vm.setDisplayTypeByDeviceSize(_vm.data, 'sp'),attrs:{"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.data.file_url_sp,"type":"video/mp4"}})]):_vm._e(),_c('div',{staticClass:"iv2-hero-cover position-absolute"},[(_vm.data.use_button === 1 && _vm.data.is_external === 0)?_c('a',{staticClass:"iv2-hero-link d-block w-100 h-100",attrs:{"href":_vm.data.link_href,"target":"_self"}}):(_vm.data.use_button === 1 && _vm.data.is_external === 1)?_c('a',{staticClass:"iv2-hero-link d-block w-100 h-100",attrs:{"href":_vm.data.link_href,"target":"_blank"}}):_vm._e()]),_c('div',{staticClass:"iv2-hero-catchphrase container-fluid position-absolute text-center"},[_c('div',{staticClass:"row justify-content-md-start justify-content-center"},[(
              _vm.data.catchphrase_type == 'img' && _vm.data.catchphrase_image_url
            )?_c('picture',{staticClass:"iv2-hero-catchphrase__picture"},[_c('source',{attrs:{"type":"image/webp","srcset":_vm.data.catchphrase_image_url +
                _vm.$store.state.publicLang.general.image_webp}}),_c('img',{staticClass:"iv2-hero-catchphrase__img",attrs:{"src":_vm.data.catchphrase_image_url,"alt":_vm.organization.name +
                _vm.$store.state.publicLang.general.catchphrase_alt}})]):_vm._e(),_c('div',{staticClass:"iv2-hero-catchphrase__text col-md-12 mx-auto",class:_vm.data.catchphrase_type != 'text' ? 'text-center' : 'text-left'},[(_vm.data.catchphrase_type == 'text' && _vm.data.catchphrase_text)?_c('h2',{staticClass:"iv2-hero__main-title text-pre",domProps:{"textContent":_vm._s(_vm.data.catchphrase_text)}}):_vm._e(),(_vm.data.summary_text)?_c('h3',{staticClass:"iv2-hero__sub-title text-pre",domProps:{"textContent":_vm._s(_vm.data.summary_text)}}):_vm._e()])]),(_vm.data.use_button == 1)?_c('div',{staticClass:"row mt-3",class:_vm.data.catchphrase_type != 'text'
              ? 'justify-content-md-start justify-content-center'
              : 'text-md-left text-sm-center'},[_c('span',[_c('a',{staticClass:"iv2-hero-btn is-btn-ghost2 py-2 pr-5 pl-5 position-relative",attrs:{"href":_vm.data.link_href,"target":_vm.windowSelected}},[_vm._v(_vm._s(_vm.data.button_label)),_c('i',{staticClass:"fas fa-play position-absolute d-flex",staticStyle:{"top":"0px","bottom":"0px","align-items":"center","right":"15px"}})])])]):_vm._e()])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }