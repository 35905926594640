import Repository from "./repository";
import store from "@/store/index.js";

export default {
  index(params) {
    params.hl = store.state.language;
    return Repository.get(`/news`, { params });
  },

  categories(type, recommend) {
    const lang = store.state.language;
    return Repository.get(`/news/categories`, {
      params: {
        hl: lang,
        type: type,
        is_recommend: recommend,
      },
    });
  },

  show(id, preview, token) {
    const lang = store.state.language;
    if (preview == "preview" && token) {
      return Repository.get(`/preview/news/${id}/show`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          hl: lang,
        },
      });
    } else {
      return Repository.get(`/news/${id}/show`, {
        params: {
          hl: lang,
        },
      });
    }
  },
};
