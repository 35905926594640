<template>
  <section
    v-if="displayRss"
    class="iv2-rss theme__sub-bg pos-right"
    :style="addValiables()"
  >
    <div class="container-fluid">
      <div class="iv2-rss__heading row clearfix">
        <div class="column full text-center theme__heading">
          <h3>
            <a
              :href="rss.link"
              class="theme__heading-text font-weight-bold"
              target="_blank"
              rel="noopener"
            >
              {{ $store.state.publicLang.general.rss }}
            </a>
          </h3>
          <p class="theme__heading-subtext m-0">
            {{ rss.name }}
          </p>
        </div>
        <div class="column full">
          <div class="spacer height-40"></div>
        </div>
      </div>
      <div class="row clearfix">
        <div
          v-for="item in rss.articles"
          :key="item.id"
          class="iv2-rss__item col-md-6"
        >
          <a
            :href="item.url"
            class="iv2-rss__item--link"
            target="_blank"
            rel="noopener"
          >
            <div class="iv2-rss__items--block column full">
              <small>{{ item.created_at }}</small>
              <h4 class="iv2-rss__items-title">{{ item.title }}</h4>
              <p class="iv2-rss__items-summary mt-0 mb-2">
                {{ item.discription }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: Object,
    rss: Object,
    theme: Object,
    news: Array,
    newsInfo: Object,
  },

  data() {
    return {
      displayRss: "",
      posClass: "",
    };
  },

  created() {
    if (this.rss.is_linked_blog === 0 || this.rss.articles.length === 0) {
      this.displayRss = false;
    } else {
      this.displayRss = true;
    }
  },

  methods: {
    addValiables() {
      return {
        "--bg-color": this.theme.basic_bg_color,
      };
    },
  },
};
</script>

<style lang="scss">
.iv2-rss {
  margin: 200px 0 0;
  padding: 80px 0;
  position: relative;

  &.pos-left::before,
  &.pos-right::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    background: var(--main-bg);
  }

  &.pos-left::before {
    left: 0;
  }

  &.pos-right::before {
    right: 0;
  }

  .iv2-rss__heading {
    position: absolute;
    top: -120px;
    z-index: 1;
    width: 100%;

    .theme__heading-subtext {
      color: #fff;
    }
  }

  .iv2-rss__item {
    margin: 15px 0;

    .iv2-rss__item--link {
      color: #000;
      text-decoration: none;

      .iv2-rss__items--block {
        padding: 0.5rem 1rem;
        -webkit-box-shadow: 0px 0px 20px rgba(100, 100, 100, 0.1);
        box-shadow: 0px 0px 20px rgba(100, 100, 100, 0.1);
        height: 100%;
        background-color: #fff;
        color: #000;

        &:hover {
          transition: 0.3s;
          transform: scale(1.01);
        }

        .iv2-rss__items-title {
          font-size: 22px;
        }

        .iv2-rss__items-summary {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }
}

// レスポンシブ対応

@media (min-width: 1401px) {
  .iv2-rss {
    &.pos-left::before,
    &.pos-right::before {
      width: 8vw;
    }
  }
}

@media (max-width: 1400px) {
  .iv2-rss {
    &.pos-left::before,
    &.pos-right::before {
      width: 3vw;
    }
  }
}

@media (max-width: 991px) {
  .iv2-rss {
    padding: 50px 0 25px;
  }
}
</style>
