<template>
  <footer class="iv2-footer theme__footer-bg" :style="pageStyle">
    <div class="iv2-footer__container theme__footer-text container-fluid">
      <div class="row">
        <div class="col-xl-6">
          <div class="iv2-footer__logo">
            <router-link :to="'/' + lang + query">
              <picture>
                <source
                  type="image/webp"
                  :srcset="
                    information.logo_image_footer +
                    $store.state.publicLang.general.image_webp
                  "
                />
                <img
                  :src="information.logo_image_footer"
                  class="iv2-footer__logo-img m-0"
                  :alt="organization.name + $store.state.publicLang.general.alt"
                />
              </picture>
            </router-link>
          </div>

          <ul class="iv2-footer__nav-list list-unstyled">
            <li
              v-for="nav in footer"
              :key="nav.id"
              class="iv2-footer__nav-item"
            >
              <router-link
                v-if="nav.link_type == 'internal'"
                :to="nav.link_href + lang + query"
                :target="nav.target_type"
                class="iv2-footer__nav-link theme__footer-link"
                >{{ nav.item_name }}</router-link
              >
              <a
                v-else-if="nav.link_type == 'external'"
                :href="nav.link_href"
                :target="nav.target_type"
                rel="noopener"
                class="iv2-footer__nav-link theme__footer-link"
                >{{ nav.item_name }}</a
              >
            </li>
          </ul>
        </div>
        <div class="iv2-footer__info col-xl-6 text-left">
          <p
            v-if="organization.name"
            class="size-14 m-0"
            style="line-height: 1.5"
          >
            {{ organization.name }}
          </p>
          <p
            v-if="organization.zip_code"
            class="size-14 m-0"
            style="line-height: 1.5"
          >
            {{ "〒" + organization.zip_code }}
          </p>
          <div style="line-height: 1.5">
            <span v-if="organization.prefecture_name" class="size-14 m-0">{{
              organization.prefecture_name
            }}</span>
            <span v-if="organization.city_name" class="size-14 m-0">{{
              organization.city_name
            }}</span>
            <span v-if="organization.town_name" class="size-14 m-0">{{
              organization.town_name
            }}</span>
            <span v-if="organization.building_name" class="size-14 m-0">{{
              organization.building_name
            }}</span>
          </div>
          <p
            v-if="organization.tel_number"
            class="size-38 font-weight-bold m-0 mt-2"
            style="line-height: 1.5"
          >
            <span class="mr-2">{{ $store.state.themeLang.general.tel }}</span>
            <span>{{ organization.tel_number }}</span>
          </p>
          <p
            v-if="organization.opening_hours"
            style="line-height: 1.5"
            class="size-14 m-0"
          >
            {{ organization.opening_hours }}
          </p>
          <p
            v-if="organization.free_text"
            style="line-height: 1.5"
            class="size-14 m-0"
          >
            {{ organization.free_text }}
          </p>
          <p class="size-14 mt-4">
            {{ information.meta_description }}
          </p>
        </div>
        <div class="iv2-footer__sns d-flex col-12 mt-3">
          <ul class="d-flex list-unstyled p-0">
            <li v-if="sns.is_linked_facebook == 1" class="iv2-footer__sns-item">
              <a
                :href="sns.facebook_url"
                class="iv2-footer__sns-link theme__footer-sns-bg"
                target="_blank"
                rel="noopener"
              >
                <i
                  class="iv2-footer__sns-icon text-center fab fa-facebook-f"
                ></i>
              </a>
            </li>
            <li
              v-if="sns.is_linked_instagram == 1"
              class="iv2-footer__sns-item"
            >
              <a
                :href="sns.instagram_url"
                class="iv2-footer__sns-link theme__footer-sns-bg"
                target="_blank"
                rel="noopener"
              >
                <i
                  class="iv2-footer__sns-icon text-center fab fa-instagram"
                ></i>
              </a>
            </li>
            <li v-if="sns.is_linked_twitter == 1" class="iv2-footer__sns-item">
              <a
                :href="sns.twitter_url"
                class="iv2-footer__sns-link theme__footer-sns-bg"
                target="_blank"
                rel="noopener"
              >
                <i class="iv2-footer__sns-icon text-center fab fa-twitter"></i>
              </a>
            </li>
            <li v-if="sns.is_linked_line == 1" class="iv2-footer__sns-item">
              <a
                :href="sns.line_url"
                class="iv2-footer__sns-link theme__footer-sns-bg"
                target="_blank"
                rel="noopener"
              >
                <i class="iv2-footer__sns-icon text-center fab fa-line"></i>
              </a>
            </li>
            <!-- <li v-if="rss.is_linked_blog == 1" class="iv2-footer__sns-item">
                <a
                  :href="rss.url"
                  class="iv2-footer__sns-link theme__footer-sns-bg"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="iv2-footer__sns-icon text-center fas fa-rss"></i>
                </a>
              </li> -->
          </ul>
        </div>
      </div>
      <hr class="iv2-footer__hr theme__footer-hr" />
      <div
        :class="{
          translation: translation.is_use == 1 && organization.copy_right,
        }"
        class="iv2-footer__bottom d-flex"
      >
        <small v-if="organization.copy_right" class="copyright">{{
          organization.copy_right
        }}</small>
        <div v-if="translation.is_use == 1" class="iv2-language ml-4">
          <button
            class="iv2-language-btn theme__footer-text d-none d-xl-block"
            @mouseover="langSelector.active = true"
          >
            <i class="fas fa-globe theme__footer-text size-12 mr-2"></i
            >{{ langSelector.select }}
          </button>
          <ul
            @mouseleave="langSelector.active = false"
            :class="{ active: langSelector.active }"
            class="iv2-language__selector theme__footer-bg list-unstyled d-none d-xl-block"
          >
            <li
              v-for="item in translation.active_language"
              :key="item.code"
              @click="changeTranslation(item.code)"
              :class="{ opacity: item.name == langSelector.select }"
              class="iv2-language__selector-list theme__footer-text"
            >
              {{ item.name }}
            </li>
          </ul>
          <button
            class="iv2-language-btn theme__footer-text d-block d-xl-none"
            @click="activeSelect"
          >
            <i class="fas fa-globe theme__footer-text size-12 mr-2"></i
            >{{ langSelector.select }}
          </button>
          <ul
            :class="{ active: langSelector.active }"
            class="iv2-language__selector theme__footer-bg list-unstyled d-block d-xl-none"
          >
            <li
              v-for="item in translation.active_language"
              :key="item.code"
              @click="changeTranslation(item.code)"
              :class="{ opacity: item.name == langSelector.select }"
              class="iv2-language__selector-list theme__footer-text"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    footer: Array,
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
    translation: Object,
    theme: Object,
  },

  data() {
    return {
      query: "",
      lang: "",
      langSelector: {
        active: false,
        select: "",
      },
      pageStyle: {
        backgroundImage: "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      },
    };
  },

  created() {
    /** language初期値のセットアップ */
    var query = this.translation.active_language.filter(
      (e) => e.code == this.$route.query.hl
    );
    var cookie = this.translation.active_language.filter(
      (e) => e.code == this.$cookies.get("iv-language")
    );
    var type = this.translation.active_language.filter(
      (e) => e.code == this.translation.default_lang
    );
    if (query.length > 0) {
      this.langSelector.select = query[0].name;
    } else if (cookie.length > 0) {
      this.langSelector.select = cookie[0].name;
    } else {
      this.langSelector.select = type[0].name;
    }

    if (this.theme.footer_bg_image) {
      this.pageStyle.backgroundImage =
        "url('" + this.theme.footer_bg_image + "')";
    }
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  methods: {
    activeSelect() {
      this.langSelector.active = !this.langSelector.active;
    },

    /** language変更時のcookie保存処理 */
    changeTranslation(val) {
      this.langSelector.active = false;
      // cookie保存
      this.$cookies.set("iv-language", val, "30min");

      // ページの再レンダリング
      var route = this.$route.path;
      this.$router.push({ path: route });
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
  },
};
</script>

<style lang="scss">
.iv2-footer__container {
  .iv2-footer__logo {
    margin-bottom: 25px;

    .iv2-footer__logo-img {
      max-width: 360px;
      max-height: 200px;
    }
  }

  .iv2-footer__nav-list {
    .iv2-footer__nav-item {
      font-size: 16px;
      margin-right: 30px;
      display: inline-block;
      position: relative;

      .iv2-footer__nav-link {
        text-decoration: none;
      }

      &::after {
        content: "/";
        position: absolute;
        top: 50%;
        right: -15px;
        transform: translateY(-50%);
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }

  .iv2-footer__sns {
    justify-content: end;

    .iv2-footer__sns-item {
      .iv2-footer__sns-link {
        margin-right: 15px;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        position: relative;

        .iv2-footer__sns-icon {
          font-size: 22px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:last-child {
        .iv2-footer__sns-link {
          margin-right: 0;
        }
      }
    }
  }

  .iv2-footer__hr {
    padding: 0;
  }

  .iv2-footer__bottom {
    justify-content: center;

    &.translation {
      justify-content: space-between;

      .iv2-language {
        .iv2-language__selector {
          right: 0;
        }
      }
    }

    .iv2-language {
      position: relative;

      .iv2-language-btn {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        border-radius: 0;
        background: transparent;
        font-size: 13px;
      }

      .iv2-language__selector {
        padding: 5px 0px;
        position: absolute;
        top: 0;
        transform: translateY(-110%);
        width: 130px;
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid;
        border-radius: 5px;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden;

        &.active {
          opacity: 1;
          visibility: inherit;
        }

        .iv2-language__selector-list {
          padding: 3px 13px;
          cursor: pointer;

          &.opacity {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  .iv2-footer {
    padding: 80px 100px 40px;
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-footer__container {
    .iv2-footer__hr {
      margin: 10px 0;
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-footer {
    padding: 50px 0 30px;
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-footer {
    padding: 30px 0;
    margin-bottom: 60px;
    padding-bottom: calc(env(safe-area-inset-bottom));
  }

  .iv2-footer__container {
    padding-bottom: 20px;

    .iv2-footer__logo {
      margin: 25px auto;
      text-align: center;

      .iv2-footer__logo-img {
        max-width: 80%;
      }
    }

    .iv2-footer__info {
      display: none;
    }

    .iv2-footer__sns {
      justify-content: center;
    }
  }
}
</style>
