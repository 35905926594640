<template>
  <header
    :class="{ scroll: scrollY > startScore }"
    :style="addValiables()"
    class="iv2-global"
  >
    <div class="iv2-global__items">
      <h1 class="iv2-global__logo">
        <router-link
          v-if="information.sp_logo_image_header"
          :to="'/' + lang + query"
          class="iv2-global__link"
        >
          <picture class="d-none d-md-inline">
            <source
              type="image/webp"
              :srcset="
                information.logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
          <picture class="d-md-none">
            <source
              type="image/webp"
              :srcset="
                information.sp_logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.sp_logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
        </router-link>
        <router-link v-else :to="'/' + lang + query" class="iv2-global__link">
          <picture>
            <source
              type="image/webp"
              :srcset="
                information.logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
        </router-link>
      </h1>
      <div
        v-if="global.length > 0 || extra.length > 0"
        class="iv2-global__menu d-xl-none d-flex text-center"
      >
        <div @click="touchHandler" class="iv2-global__btn d-inline-block">
          <div
            :class="{ active: activeMenu }"
            class="iv2-global__btn-icon position-relative p-0"
          >
            <span
              :class="{
                'theme__global-icon': !activeMenu,
                'theme__nav-icon': activeMenu,
              }"
              class="iv2-global__top-drawer"
            ></span>
            <span
              :class="{
                'theme__global-icon': !activeMenu,
                'theme__nav-icon': activeMenu,
              }"
              class="iv2-global__center-drawer"
            ></span>
            <span
              :class="{
                'theme__global-icon': !activeMenu,
                'theme__nav-icon': activeMenu,
              }"
              class="iv2-global__bottom-drawer"
            ></span>
          </div>
          <span
            :class="{
              'theme__global-text': !activeMenu,
              'theme__nav-text': activeMenu,
            }"
            class="iv2-global__menu-text d-block"
          >
            {{ menuText }}
          </span>
        </div>
      </div>
    </div>

    <nav
      :class="{ active: activeMenu }"
      class="iv2-nav theme__nav-bg theme__nav-text"
    >
      <div
        class="iv2-nav__block"
        :class="{
          'text-white nav__text--shadow': scrollY <= startScore && wideDisplay,
        }"
      >
        <ul v-if="global.length > 0" class="iv2-nav__list list-unstyled">
          <li v-for="nav in global" :key="nav.id" class="iv2-nav__item">
            <router-link
              v-if="nav.link_type == 'internal'"
              :to="nav.link_href + lang + query"
              @click.native="closeMenu"
              :target="nav.target_type"
              :class="{ 'text-white': scrollY <= startScore && wideDisplay }"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</router-link
            >
            <a
              v-else-if="nav.link_type == 'external'"
              :href="nav.link_href"
              @click="closeMenu"
              :target="nav.target_type"
              rel="noopener"
              :class="{
                'text-white': scrollY <= startScore && wideDisplay,
              }"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</a
            >
          </li>
        </ul>

        <div v-if="extra.length > 0" class="iv2-nav__sub-menu row">
          <div
            v-for="subnav in extra"
            :key="subnav.id"
            class="iv2-nav__sub-menu-item col-md-4"
          >
            <router-link
              v-if="subnav.link_type == 'internal'"
              :to="subnav.link_href + lang + query"
              :target="subnav.target_type"
              @click.native="closeMenu"
              class="iv2-nav__sub-menu-link theme__nav-link"
            >
              <i
                class="iv2-nav__sub-menu-item-icon"
                :class="subnav.item_icon"
              ></i>
              <span class="iv2-nav__sub-menu-item-text">{{
                subnav.item_name
              }}</span>
            </router-link>
            <a
              v-else-if="subnav.link_type == 'external'"
              :href="subnav.link_href"
              :target="subnav.target_type"
              @click="closeMenu"
              rel="noopener"
              class="iv2-nav__sub-menu-link theme__nav-link"
            >
              <i
                class="iv2-nav__sub-menu-item-icon d-block"
                :class="subnav.item_icon"
              ></i>
              <span class="iv2-nav__sub-menu-item-text d-block">{{
                subnav.item_name
              }}</span>
            </a>
          </div>
        </div>

        <hr class="theme__nav-hr d-xl-none" />

        <div class="iv2-nav__footer">
          <div class="iv2-nav__mobile-sns mb-3">
            <ul class="iv2-nav__mobile-sns-list mb-0 list-unstyled">
              <li
                v-if="sns.is_linked_facebook == 1"
                class="iv2-nav__mobile-sns-item theme__nav-sns-bg"
              >
                <a
                  :href="sns.facebook_url"
                  class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li
                v-if="sns.is_linked_instagram == 1"
                class="iv2-nav__mobile-sns-item theme__nav-sns-bg"
              >
                <a
                  :href="sns.instagram_url"
                  class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </li>
              <li
                v-if="sns.is_linked_twitter == 1"
                class="iv2-nav__mobile-sns-item theme__nav-sns-bg"
              >
                <a
                  :href="sns.twitter_url"
                  class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li
                v-if="sns.is_linked_line == 1"
                class="iv2-nav__mobile-sns-item theme__nav-sns-bg"
              >
                <a
                  :href="sns.line_url"
                  class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                  target="_blank"
                  rel="noopener"
                >
                  <i class="fab fa-line"></i>
                </a>
              </li>
            </ul>
          </div>
          <div
            v-if="organization.copy_right"
            class="iv2-nav__copyright text-center"
          >
            <small>{{ organization.copy_right }}</small>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  props: {
    global: Array,
    extra: Array,
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
    startScore: Number,
    scrollY: Number,
    theme: Object,
  },

  data() {
    return {
      menuText: "",
      query: "",
      lang: "",
      activeMenu: true,
      wideDisplay: true,
      activeParent: false,
      activeNav: "",
      navColorClass: "",
      navChildColor: "",
      environment: "",
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        if (window.innerWidth <= 1199) {
          this.activeMenu = false;
          this.activeParent = false;
          this.menuText = "MENU";
        }
        const el = document.body;
        el.classList.remove("noscroll");
      }
    },
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.menuText = "MENU";

    if (window.innerWidth <= 1199) {
      this.activeMenu = false;
      this.wideDisplay = false;
    } else {
      this.activeMenu = true;
      this.wideDisplay = true;
    }
  },

  mounted() {
    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    activeList(key) {
      if (this.activeParent === key) {
        this.activeParent = null;
      } else {
        this.activeParent = key;
      }
    },

    handleResize() {
      if (window.innerWidth >= 1199) {
        this.navColorClass = "theme__global-bg";
      } else {
        this.navColorClass = "theme__nav-bg";
      }

      if (window.innerWidth >= 1199) {
        this.navChildColor = "theme__nav-bg";
      } else {
        this.navChildColor = "";
      }
    },

    touchHandler() {
      this.activeMenu = !this.activeMenu;

      if (this.activeMenu == true) {
        const el = document.body;
        el.classList.add("noscroll");
        this.menuText = "CLOSE";
      } else {
        const el = document.body;
        el.classList.remove("noscroll");
        this.menuText = "MENU";
      }
    },

    closeMenu() {
      if (window.innerWidth <= 1199) {
        this.activeMenu = false;
        this.activeParent = false;
        const el = document.body;
        el.classList.remove("noscroll");
        this.menuText = "MENU";
      }
    },

    addValiables() {
      return {
        "--bg-color": this.theme.basic_bg_color,
      };
    },
  },
};
</script>

<style lang="scss">
.iv2-global {
  &.scroll {
    .iv2-global__items {
      align-items: center;
    }
  }

  .iv2-global__items {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: 0.3s;

    .iv2-global__logo {
      .iv2-global__logo-img {
        transition: 0.3s;
        display: block;
      }
    }

    .iv2-global__menu {
      top: 50%;
      left: 0;
      right: 0;

      .iv2-global__btn {
        .iv2-global__btn-icon {
          width: 30px;
          height: 25px;
          margin: 0 auto;
          cursor: pointer;

          .iv2-global__top-drawer,
          .iv2-global__center-drawer,
          .iv2-global__bottom-drawer {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            margin: auto;
            right: 0;
            left: 0;
            transition: 0.3s;
          }

          .iv2-global__top-drawer {
            top: 2px;
          }

          .iv2-global__center-drawer {
            top: 0;
            bottom: 0;
            visibility: visible;
            opacity: 1;
          }

          .iv2-global__bottom-drawer {
            bottom: 2px;
          }

          &.active {
            .iv2-global__top-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(-45deg);
            }

            .iv2-global__center-drawer {
              visibility: hidden;
              opacity: 0;
            }

            .iv2-global__bottom-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(45deg);
            }
          }

          &:focus {
            outline: 0;
          }
        }
      }

      .iv2-global__menu-text {
        font-size: 10px;
        font-weight: 400;
        line-height: 1;
      }
    }
  }

  .iv2-nav {
    width: 100%;
    z-index: 1;
    visibility: hidden;
    display: none;

    &.active {
      visibility: visible;
      display: block;
    }

    .iv2-nav__block {
      .iv2-nav__list {
        .iv2-nav__item {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 2px;

          .iv2-nav__link {
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  @keyframes tooltipShow {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fade-move {
    0% {
      transform-origin: 0% 0;
      transform: translate3d(0%, 0%, 0px) scale(1, 0);
    }

    100% {
      transform-origin: 0% 0;
      transform: translate3d(0%, 0%, 0px) scale(1, 1);
    }
  }

  .iv2-global {
    display: flex;
    transition: 0.3s;

    .iv2-global__items {
      padding: 30px 30px 30px 100px;
      transition: 0.3s;

      .iv2-global__logo {
        margin: 0;
        z-index: 11;

        .iv2-global__logo-img {
          max-width: 360px;
          max-height: 100px;
        }
      }
    }

    .iv2-nav {
      height: 100%;

      .iv2-nav__block {
        height: 100%;
        padding: 62px 0;
        transition: 0.3s;

        .iv2-nav__list {
          display: flex;
          margin: 0;
          align-items: center;
          justify-content: flex-end;

          .iv2-nav__item {
            line-height: 2.6;
            height: 100%;
            letter-spacing: 0;
            white-space: nowrap;
            position: relative;
            margin-right: 30px;

            &::after {
              content: "/";
              position: absolute;
              top: 50%;
              right: -15px;
              transform: translateY(-50%);
            }

            &:last-child {
              &::after {
                content: none;
              }
            }

            .iv2-nav__link {
              transition: 0.3s;
            }
          }
        }

        &.nav__text--shadow {
          .iv2-nav__list {
            .iv2-nav__item {
              text-shadow: 0 0 6px rgba(51, 51, 51, 0.5);
            }
          }
        }

        .iv2-nav__sub-menu,
        .iv2-nav__footer {
          display: none;
        }
      }
    }

    &.scroll {
      .iv2-global__items {
        padding: 15px 30px 15px 100px;

        .iv2-global__logo {
          .iv2-global__logo-img {
            max-height: 70px;
          }
        }
      }

      .iv2-nav {
        .iv2-nav__block {
          padding: 33px 0;
        }
      }
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  @keyframes tooltipShow {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }

    to {
      opacity: 1;
    }
  }

  .iv2-global {
    .iv2-global__items {
      height: 100%;

      .iv2-global__logo {
        transition: 0.3s;
      }

      .iv2-global__menu {
        height: 100%;
        z-index: 11;

        .iv2-global__btn {
          line-height: 1;
        }

        .iv2-global__menu-text {
          margin-top: 8px;
        }
      }
    }

    .iv2-nav {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;

      .iv2-nav__block {
        padding: 50px 20px 20px;
        animation: tooltipShow 0.5s linear 0s;

        .iv2-nav__list {
          display: block;
          margin: auto;

          .iv2-nav__item {
            position: relative;
            display: block;
            margin: 0;

            .iv2-nav__link {
              display: block;
              padding: 12px 15px;
              position: relative;

              &::before {
                content: "-";
                position: absolute;
                left: 0;
              }
            }
          }
        }

        .iv2-nav__sub-menu {
          margin-top: 2rem;

          .iv2-nav__sub-menu-item {
            margin-bottom: 1rem;

            .iv2-nav__sub-menu-link {
              border: 1px solid;
              border-radius: 30px;
              padding: 12px;
              -webkit-transition: 0.3s;
              transition: 0.3s;
              display: flex;
              justify-content: center;
              align-items: center;

              &:hover {
                text-decoration: none;
                opacity: 0.5;
              }

              .iv2-nav__sub-menu-item-icon {
                font-size: 1.2rem;
                margin-right: 10px;
              }

              .iv2-nav__sub-menu-item-text {
                font-weight: bold;
              }
            }
          }
        }
      }

      .iv2-nav__footer {
        .iv2-nav__mobile-sns {
          .iv2-nav__mobile-sns-list {
            text-align: center;

            .iv2-nav__mobile-sns-item {
              font-size: 1.3em;
              display: inline-block;
              margin-right: 20px;
              width: 40px;
              height: 40px;
              border-radius: 50%;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        margin: 30px;

        .iv2-global__logo-img {
          max-width: 200px;
          max-height: 110px;
        }
      }

      .iv2-global__menu {
        .iv2-global__btn {
          width: 74px;
          height: 100%;
          padding: 15px 10px;
        }
      }
    }

    &.scroll {
      .iv2-global__items {
        .iv2-global__logo {
          margin: 10px 30px;

          .iv2-global__logo-img {
            max-height: 50px;
          }
        }
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        margin: 10px 15px;

        .iv2-global__logo-img {
          max-width: 120px;
          max-height: 70px;
        }
      }

      .iv2-global__menu {
        padding: 13px 10px;

        .iv2-global__menu-text {
          transform: scale(0.8);
          margin-top: 5px;
        }

        .iv2-global__btn {
          .iv2-global__btn-icon {
            width: 20px;
            height: 20px;
            margin-bottom: 3px;
          }
        }
      }
    }

    .iv2-nav {
      padding-bottom: 10rem;
      padding-bottom: calc(10rem + env(safe-area-inset-bottom));

      .iv2-nav__block {
        .iv2-nav__list {
          padding: 0;
        }
      }
    }

    &.scroll {
      .iv2-global__items {
        .iv2-global__logo {
          margin: 5px 15px;

          .iv2-global__logo-img {
            max-height: 40px;
          }
        }
      }
    }
  }
}
</style>
