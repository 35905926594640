<template>
  <section
    v-if="
      items.is_open_news == 1 &&
      news.length > 0 &&
      params.limit > 0 &&
      newsInfo.indexOpenType == 1
    "
    class="iv2-section-news theme__sub-bg"
    :style="addValiables()"
  >
    <div class="container-fluid">
      <div class="row clearfix">
        <div
          class="iv2-section-news-title theme__news-heading col-lg-2 d-lg-block d-none text-left"
        >
          <h3 class="iv2-section-news-title__title font-weight-bold">
            {{ newsInfo.subtitle }}
          </h3>
          <p class="iv2-section-news-title__subtitle m-0">
            {{ newsInfo.title }}
          </p>
        </div>

        <!-- TABLET or SP -->
        <div
          class="iv2-section-news-title d-lg-none col-12 theme__heading text-center"
        >
          <h3 class="iv2-section-news-title__title">
            <span class="font-weight-bold">
              {{ newsInfo.subtitle }}
            </span>
          </h3>
          <p class="iv2-section-news-title__subtitle m-0">
            {{ newsInfo.title }}
          </p>
        </div>

        <div class="iv2-section-news__edit col-lg-10">
          <NewsLine
            v-for="item in limitCount"
            :key="item.id"
            :content="item"
            :current="'top'"
          />
        </div>
      </div>

      <div class="row clearfix">
        <div class="column full">
          <div class="spacer height-20"></div>
        </div>

        <div class="column full">
          <div class="text-center">
            <span>
              <router-link
                :to="{
                  name: 'news',
                  query: Object.assign({}, $route.query),
                }"
                class="iv2-top-news-btn theme__main-btn is-btn is-btn-ghost2 size-14"
                >{{ $store.state.publicLang.page.news_button }}</router-link
              >
              <!-- <a
              v-if=“rss.is_linked_blog == 1”
              :href=“rss.url”
              target=“_blank”
              rel=“noopener”
              class=“iv2-top-news-btn theme__main-btn is-btn is-btn-ghost2 size-14”
            >
              <i class=“fas fa-rss”></i>{{ $store.state.publicLang.page.rss_button }}
            </a> -->
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NewsLine from "@/components/items/BaseNewsLine.vue";

export default {
  components: {
    NewsLine,
  },

  props: {
    items: Object,
    rss: Object,
    theme: Object,
    news: Array,
    newsInfo: Object,
  },

  data() {
    return {
      params: {
        type: "news",
        limit: "",
      },
    };
  },

  computed: {
    limitCount() {
      return this.news.slice(0, this.params.limit);
    },
  },

  created() {
    this.params.limit = this.items.display_news_number;
  },

  methods: {
    addValiables() {
      return {
        "--bg-color": this.theme.basic_bg_color,
      };
    },
  },
};
</script>

<style lang="scss">
.iv2-section-news {
  padding: 80px 0;
  position: relative;

  .iv2-section-news-title__title {
    font-size: 1.875rem;
    margin: 0.3rem 0;
  }
  .iv2-section-news-title__subtitle {
    font-size: 0.875rem;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    background: var(--main-bg);
  }

  .iv2-top-news-btn {
    border: 0;
    letter-spacing: 2px;
    max-width: 100%;
    width: 250px;
    margin: 5px 15px;
    height: 50px;
    line-height: 2;
  }
}

// レスポンシブ対応

@media (min-width: 1401px) {
  .iv2-section-news {
    &::before {
      width: 8vw;
    }
  }
}

@media (max-width: 1400px) {
  .iv2-section-news {
    &::before {
      width: 3vw;
    }
  }
}

@media (min-width: 992px) {
  .iv2-section-news-title {
    padding-right: 25px;

    &::before {
      position: absolute;
      content: "";
      width: 1px;
      height: 100%;
      bottom: 0;
      right: -1px;
      transform: translateX(-50%);
      top: 0;
    }

    &::after {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100px;
      right: -5px;
      bottom: 0;
    }
  }

  .iv2-section-news__edit {
    padding: 80px 0 40px 100px;
  }
}

@media (max-width: 991px) {
  .iv2-section-news {
    padding: 50px 0;
  }

  .iv2-section-news__edit {
    padding: 30px 15px 40px;
  }
}
</style>
